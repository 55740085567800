.news-text
{
  border-top: solid 1px #cccccc;
  margin-top: 10px;
  padding-top: 10px;
  font-size: 12pt;
}

.news-text-no-border
{
  margin-top: 10px;
  padding-top: 10px;
  font-size: 12pt;
}