.page-title
{
  font-size: 12pt;
  color: #000000
}

.exhibition-container
{
  width: 700px;
  padding: 14px 4px 14px 4px;
}