.contact-information
{
  font-size: 12pt;
  font-weight: bold;
}

.contact-email
{
  color: black;
  text-decoration: none;
}
