body, td
{
  font-family: arial;
  font-size: 9pt;
  margin: 0 0 0 0;
}

a
{
  color: #993300;
}
