.menu-item, 
.menu-item-selected
{
  display: block;
  width: 200px;
  height: 50px;
  padding-left: 10px;
  padding-top: 15px;
  margin: 4px 4px 4px 4px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 11pt;
}

.menu-item
{
  background-color: #aaaaaa;
}

.menu-item-selected
{
  background-color: #cc6666;
}
