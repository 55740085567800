.page-title
{
  font-size: 12pt;
  color: #000000
}

.parent-title
{
  font-size: 12pt;
  color: #888888;
}
