.page-title
{
  font-size: 12pt;
  color: #000000
}

.press-clipping
{
 border: solid 1px #cccccc;
 width:700px;
 height:500px;
 background-color:#ffffff;
 overflow:auto;
}