.page-title-home
{
  font-size: 30pt;
  color: #000000;
  text-align: left;
  padding-top: 21pt;
  padding-bottom: 39pt;
}


.page-title-non-home
{
  font-size: 20pt;
  padding: 10px 10px 10px 10px;
  color: #888888;
  text-align: left;
}


.body-content
{
  width: 1000px;
}

.copyright
{
  padding: 40px 20px 20px 10px;
  color: #666666;
}
